:root {
  --lighter-gray: #d9d9d9;
  --light-gray: #adadad;
  --gray: #4f4f4f;
  /* --blue: #6f6af8; */
  --primary: #E47E7B;
  --green: #08cb56;
  --red: #d80027;
  --sidebar-width: 250px;
  --topbar-height: 78px;
}
.topbar_content {
  display: flex;
  width: 100%;
  align-items: center;
}
@media only screen and (max-width: 820px) {
  .topbar_content {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .topbar__header {
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
  .video-container_medium > iframe {
    max-width: 100%;
  }
  .video-container_small > iframe {
    max-width: 100%;
  }
}

@media only screen and (max-width: 420px) {
  .topbar__header {
    font-size: 14px;
  }
}

* {
  box-sizing: border-box;
}
/* h1, h2,h3,h4 {
  padding: 0;
  margin: 0;
} */
body {
  font-size: 14px;
  color: var(--gray);
  background-color: white;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.filter_gray {
  filter: invert(29%) sepia(0%) saturate(1%) hue-rotate(339deg) brightness(103%)
    contrast(95%);
}
.filter_white {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(42deg)
    brightness(93%) contrast(118%);
}
.tx_primary {
  color: var(--primary);
}
.tx_secondary {
  color: var(--light-gray);
}
.tx_gray {
  color: var(--gray);
}
.bg_primary {
  background-color: var(--primary);
}
.bg_secondary {
  background-color: var(--gray);
}
.bg_success {
  background-color: var(--green);
}
.tx_small {
  font-size: 14px;
}
.tx_med {
  font-size: 16px;
}
.tx_big {
  font-size: 20px;
}

.main-screen {
  display: flex;
  min-height: 100vh;
  width: 100%;
}

/* SIDE BAR */
.sidebar {
  /* background-color: lime; */
  min-width: var(--sidebar-width);

  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--light-gray);
}
.hide_sidebar .sidebar {
  display: none;
}
.logo {
  padding: 28px;
  max-width: 228px;
}
.nav {
  min-height: 42px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  flex-grow: 1;
  color: var(--gray);
  padding: 0 16px;
  gap: 8px;
}
.nav_horizontal {
  flex-direction: row;
  flex-wrap: wrap;
}
.nav.nav_horizontal a {
  gap: 8px;
  align-items: center;
}
.nav a {
  text-decoration: none;
  color: var(--gray);
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid var(--primary);
  display: flex;
  gap: 28px;
}
.nav a:hover {
  background-color: #ffffff;
  color: var(--primary);
}
.nav a:hover img {
  filter: none;
}
.nav img {
  filter: invert(29%) sepia(0%) saturate(1%) hue-rotate(339deg) brightness(103%)
    contrast(95%);
}
.nav_active {
  background-color: var(--primary);
  color: #fff;
}
.nav_active img {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(42deg)
    brightness(93%) contrast(118%);
}
a.nav_active {
  color: #fff;
}
.sidebar__contacts {
  padding-left: 40px;
  padding-bottom: 40px;
}
.contacts__header {
  font-size: 18px;
  color: var(--primary);
}
.contacts__content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.contacts__item {
  display: flex;
  gap: 8px;
}

/* MAIN PART */
.main_part {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: #ededed;
  padding-top: calc(var(--topbar-height) - 20px);
}

/* TOP BAR */
.topbar {
  height: var(--topbar-height);
  /* overflow-y: auto; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  position: fixed;
  top: 0;
  left: var(--sidebar-width);
  right: 0;
  z-index: 2;
}
.hide_sidebar .topbar {
  left: 0;
}

.topbar__header {
  /* padding: 0 28px; */
  padding: 0;
  flex-grow: 1;
}
.topbar__flags {
  padding: 0 28px;
  justify-self: flex-end;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
}
.topbar__logout {
  display: flex;
  justify-content: center;
  padding: 0 28px;
  border-left: 1px solid var(--light-gray);
  cursor: pointer;
}
@media only screen and (max-width: 820px) {
  .topbar__header {
    padding: 0 8px;
  }
  .topbar__flags {
    padding: 0 8px;
    gap: 4px;
  }
  .topbar__logout {
    padding: 0 8px;
  }
  .main-content {
    margin-bottom: 32px;
  }
}

.main-content {
  padding: 28px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

/* ReservationFind */
.header-tag {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.header-tag,.header-tag__title {
  padding: 20px;
  border-radius: 8px;
  /* display: inline-block; */
  font-size: 16px;
  font-weight: normal;
}
.header-tag__title_big {
  color: var(--light-gray);
  font-size: 32px;
  text-align: center;
}

.header-tag h3 {
  padding: 0;
  margin: 0;
}
/* .header-tag.tag_many_lines h3 {
  padding: 4px 0;
} */
/* .header-tag .header-tag__title {
  color: var(--gray);
  padding-bottom: 8px;
} */
.off_zero {
  padding: 0;
  margin: 0;
}
.off_small {
  padding: 16px 0;
  margin: 0;
}
.form-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.form-card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  /* min-width: 420px; */
  /* max-width: 50%; */
}
.form-card h3 {
  padding: 0;
  margin-top: 0;
}
.control__wrap {
  display: flex;
  flex-wrap: wrap;
  /* gap: 8px; */
}
.form__control {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 240px;
  font-size: 16px;
  /* width: 50%; */
  padding: 8px;
  padding-top: 20px;
}
.control_wide {
  min-width: 100%;
}
.control_center {
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
.control_right {
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: end;
}
.header {
  font-size: 20px;
  padding: 0;
  margin-top: 0;
}
.control-label {
  display: inline-block;
  font-size: 20px;
}
.input_outline {
  padding: 8px;
  border: 1px solid var(--primary);
  border-radius: 8px;
  font-size: 18px;
}
::placeholder {
  color: var(--light-gray);
  opacity: 0.5; /* Firefox */
}
.input-date {
  padding: 8px;
  border: 1px solid var(--primary);
  border-radius: 8px;
  font-size: 18px;
}
.error-message {
  padding: 0;
  margin: 0;
  color: var(--red);
}
.suggestions-box {
  max-height: 200px;
  overflow-y: auto;
  align-self: stretch;
}

.absoluteFill {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 51;
  background-color: rgba(0, 0, 0, 0.2);
}
@keyframes spinner_animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 6em;
  height: 6em;
  border: 10px solid var(--primary); /* Light grey */
  border-top: 10px solid var(--light-gray); /* Black */
  border-radius: 50%;
  animation: spinner_animation 1.5s linear infinite;

  -webkit-box-shadow: 0px 0px 32px 4px var(--primary);
  -moz-box-shadow: 0px 0px 32px 4px var(--primary);
  box-shadow: 0px 0px 128px 32px var(--primary);
}
.modal-base {
  width: 600px;
  max-width: 80%;
  /* width: 60%; */
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  padding: 1em;
}
.modal-text {
  color: var(--primary);
  font-size: 1.8rem;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 220px;
  /* padding: 14px; */
  padding:0.5rem 1rem ;
  border-radius: 10px;
  color: white;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.btn:hover {
  -webkit-box-shadow: 0px 0px 32px 4px var(--primary);
  -moz-box-shadow: 0px 0px 32px 4px var(--primary);
  box-shadow: 0px 0px 32px 4px var(--primary);
}
.btn:active {
  filter: brightness(50%);
}
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow-x: auto;
}
.video-container_medium > iframe {
  width: 472px;
  height: 280px;
}
.video-container_small > iframe {
  width: 420px;
  height: 250px;
}

.header-info {
  display: flex;
  gap: 20px;
  align-items: center;
  border: 1px solid var(--light-gray);
}
.header-info img {
  width: 140px;
  border: 2px solid var(--red);
}
.card__header {
  border-bottom: 1px solid var(--light-gray);
  /* margin-top: 8px; */
  padding: 8px;
  padding-top: 8px;
}
/* 
input[type="file"]::file-selector-button {
  margin-bottom: -100px;
} */
.steps {
  display: flex;
  flex-direction: column;
}
.steps h3 {
  padding-bottom: 0;
  margin-bottom: 0;
}
.steps h3:first-child {
  padding-top: 0;
  margin-top: 0;
}
.steps p {
  padding-top: 0;
  margin-top: 0;
}
.steps-header {

  min-height: 100px;
}
.card_half {
  width: 50%;
}

thead {
  /* background-color: var(--primary);
  color: white; */
  padding: 20px;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid var(--gray);
}
table {
  border-collapse: collapse;
  overflow-x: auto;
  padding: 0;
}
table tbody {
  width: 100%;
}

table,
th,
td {
  border: none;
  border-spacing: 0;
  text-align: start;
  font-size: 16px;
}
th,
td {
  margin: 0;
  padding: 20px;
}
th:first-child {
  border-radius: 10px 0 0 0;
}
th:last-child {
  border-radius: 0 10px 0 0;
}
tr {
  border-bottom: 1pt solid var(--light-gray);
}
tr:last-child {
  border-bottom: none;
}
.card_scroll {
  overflow-x: auto;
  max-width: calc(100vw - var(--sidebar-width));
}

.btn-radio {
  padding: 8px;
  border: 1px solid var(--primary);
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.btn-radio__stick {
  width: 16px;
  height: 16px;
  background-color: var(--primary);
  border-radius: 50%;
}
.invisible {
  visibility: hidden;
}

.half_if_wide {
  width: 50%;
}
.pad_if_wide {
  padding: 0 20px;
}
.w520_ifw {
  max-width: 520px;
}
.w620_ifw {
  max-width: 620px;
}
@media only screen and (max-width: 620px) {
  .form-card {
    /* min-width: 100%; */
    max-width: calc(100vh- var(--sidebar-width));
  }
  .video-container {
    max-width: calc(100vh- var(--sidebar-width));
  }
  .half_if_wide {
    width: 100%;
  }
  .pad_if_wide {
    padding: 0 0;
  }
  .modal-base {
    min-width: 90%;
  }
  .w520_ifw {
    max-width: 100%;
  }
  .w620_ifw {
    max-width: 100%;
  }

  /* .card_scroll {
    overflow-x: auto;
    max-width: calc(100vw);
  } */
}

.page-content {
  width: 100%;
}

.the-code {
  justify-self: flex-end;
  align-self: center;
  display: flex;
  flex-direction: column;
  color: var(--light-gray);
}
.the-code p {
  padding: 0;
  margin: 0;
}
.the-code_extra {
  display: none;
}
.hide_sidebar .the-code_extra {
  display: flex;
  position: fixed;
  z-index: 100;
  bottom: 14px;
  right: 24px;
}
.link {
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: var(--primary);
}
.btn-link {
  font-size: 20px;
  font-weight: 800;
  color: var(--primary);
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.btn-link:hover {
  /* text-shadow: var(--primary) 1px 0 10px; */
  filter: brightness(50%);
}
.btn-link:active {
  color: var(--primary);
  filter: brightness(100%);
}
.btn_small {
  font-size: 14px;
}
.text_center {
  text-align: center;
}
.min_w480 {
  min-width: 480px;
}

.card__header-title {
  padding: 8px 0;
  margin: 0;
  font-size: 20px;
}

.par {
  font-size: 16px;
  color: var(--gray);
  padding: 0;
  margin: 0;
  padding-top: 16px;
}
.par_light {
  color: var(--light-gray);
}
.par {
  padding-top: 14px;
}
.header_big {
  font-size: 20px;
  color: var(--gray);
}
.btn-with-image {
  min-width: auto;
  align-self: center;
  font-size: 18px;

  padding: 8px;
  border-radius: 8px;
  background-color: white;
}

.dropdown {
  background-color: white;
  z-index: 12;
  display: flex;
  gap: 4px;
}


.btn_bg {
  background-color: white;
  min-width: auto;
  padding: 0.5em;
  align-self: center;
  justify-self: flex-end;
}

.step-container {
  display: flex;
  align-items: start;
}
.step-number {
  font-size: 80pt;
  font-weight: bold;
  color: var(--light-gray);
  padding-right: 8px;
}
.step-number_active {
  color: var(--primary);
}
.step-number_outline {
  padding: 8px;
  border: 1px solid var(--gray);
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  
  width: 20px;
  height: 20px;
}
.step-header {
  margin-top: 0;
  display: flex;
  gap: 4px;
}


.guest-header {
  font-size: 24px;
}

.example-img-container {
  /* align-self: flex-end; */
  padding-top: 8px;
  /* max-width: 400px; */
  display: flex;
  /* flex-direction: column; */
  /* width: 150px; */
}
.example-img {
  width: 150px;
  float: left;
  margin: 8px;
}
.example-img-container h4 {
  margin: 0;
  display: block;
}

@media only screen and (max-width: 500px) {
  .step-number {
    display: none;
  }
  .example-img {
    width: 100%;
  }
}



.control__drop {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  border-radius: 8px;
  border: 1px solid var(--light-gray);
}


.document-select {
  background-color: var(--lighter-gray);
  border-radius: 8px;
  padding: 8px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  align-self: center;
}

.lang {
  cursor: pointer;
}


.outline {
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-size: 1.4rem; */
  border: 1px solid var(--primary);
  min-height: 3rem;
  min-width: 100px;
  border-radius: 8px;
  color: var(--primary);
}
.filled {
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid var(--primary);
  min-height: 3rem;
  min-width: 100px;
  border-radius: 8px;
  background-color: var(--primary);
  color: #fff;
}

.outline_green {
  border-color: var(--green);
  color: var(--green);
}

.filled_green {
  border-color: var(--green);
  background-color: var(--green);
  color: #fff;
}

.reservations-table th div {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .reservations-table td {
  text-align: center;
  border-top: 1px solid var(--gray);
  border-bottom: 1px solid var(--gray);
  padding: 4px 0;
}
.reservations-table td:first-child {
  border-left: 1px solid var(--gray);
  border-radius: 8px;
}
.reservations-table td:nth-child(6) {
  border-right: 1px solid var(--gray);
  border-radius: 8px;
} */

.reservation-filters {
  display: flex;
  gap: 8px;
  padding: 8px 0;
}

.reservations-table th,
.reservations-table td {
  text-align: center;
}

.combined_1_to_6 td:nth-child(n+1):nth-child(-n+6) {
  padding: 0;
}

.combined_1_to_6 td div {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
}
.combined_1_to_6 td:nth-child(n+1):nth-child(-n+6) div {
  border-top: 1px solid var(--gray);
  border-bottom: 1px solid var(--gray);
}
.combined_1_to_6 td:nth-child(1) div {
  border-left: 1px solid var(--gray);
  border-radius: 8px 0 0 8px;
  padding-left: 8px;
}
.combined_1_to_6 td:nth-child(6) div {
  border-right: 1px solid var(--gray);
  border-radius: 0 8px 8px 0;
  padding-right: 8px;
}

.combined_7_8 td:nth-child(7) div,
.combined_7_8 td:nth-child(8) div {
  border: 1px solid var(--gray);
  border-radius: 8px;
  padding: 8px;
}

.combined_7 td:nth-child(7) div,
.combined_8 td:nth-child(8) div {
  border: 1px solid var(--gray);
  border-radius: 8px;
  padding: 8px;
}

.combined_1_to_3 td:nth-child(n+1):nth-child(-n+3) {
  padding: 0;
}
.combined_1_to_3 td:nth-child(n+1):nth-child(-n+3) div {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
}
.combined_1_to_3 td:nth-child(n+1):nth-child(-n+3) div {
  border-top: 1px solid var(--gray);
  border-bottom: 1px solid var(--gray);
}
.combined_1_to_3 td:nth-child(1) div {
  border-left: 1px solid var(--gray);
  border-radius: 8px 0 0 8px;
  padding-left: 8px;
}
.combined_1_to_3 td:nth-child(3) div {
  border-right: 1px solid var(--gray);
  border-radius: 0 8px 8px 0;
  padding-right: 8px;
}

.page-header {
  padding: 32px 0;
}
.page-header__title {
  font-size: 48px;
  text-align: center;
}
.page-header__subtitle {
  font-size: 24px;
  text-align: center;
  color: var(--primary);
}


.content-header { 
  padding: 8px 0;
  margin-bottom: 32px;
  border-bottom: 1px solid black;
  font-size: 32px;
}
.content-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  
  font-size: 18px;
}
.content-list_padding-left {
  padding-left: 32px;
}


.content-card-header {
  font-size: 32px;
  padding-bottom: 0;
  margin-bottom: 18px;
}

.text-primary {
  color: var(--primary);
}
.price-card {
  max-width: 400px;
  text-align: center;
}


.card-size {
  max-width: 42rem;
}
@media only screen and (max-width: 420px) {
  .card-size {
    max-width: auto;
  }
}